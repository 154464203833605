import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Auth from './Auth';

import { getMe, getGroups } from '../apis';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [verify, setVerify] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const verify = await Auth.verify();
      if (verify) {
        const me = verify ? await getMe() : [];
        const groups = verify ? await getGroups() : [];
        const group = groups.filter((obj) => obj.id === me.group);
        Auth.setPermission(me.permission);
        try {
          me.group = group[0].name;
        } catch (error) {
          me.group = 'User';
        }
        Auth.me = me;
      }
      setVerify(verify ? 2 : 1);
    };
    fetchData();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (verify !== 0) {
          if (verify && Auth.isAuthenticated()) {
            return <Component {...props} />;
          }
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
