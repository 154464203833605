const normTool = (num: number) => {
  const unitTypes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB'];

  for (let i = 0; i < unitTypes.length; i++) {
    if (Math.abs(num) < 1024.0) {
      return `${num.toFixed(2)} ${unitTypes[i]}`;
    }
    num /= 1024.0;
  }
  return `${num.toFixed(2)} YB`;
};

const getPercent = (numerator: number, denominator: number) => {
  if (numerator === 0 || denominator === 0) return '0 %';
  let result = (numerator / denominator) * 100;
  return `${result.toFixed(0)} %`;
};

const datetimeFormatConvert = (datetime: string) => {
  // convert to MM-DD HH:MM:SS
  const datetimeConvert = new Date(datetime);
  if (datetime) {
    return `${datetime.slice(5, 10)} ${datetimeConvert.toString().slice(16, 24)}`;
  }

  return datetime;
};

const parseObjectValues = (obj: any) => {
  Object.keys(obj).forEach((k) => {
    obj[k] = obj[k].toString();
  });

  return obj;
};

const getPermissionMap = (item: string): [] => {
  // input route, return permissions required
  const permissionMaps: any = {
    // route
    dashboard: ['all'],
    dashboard_monitor: ['User.management.poseidon'],
    organization: ['Organization.view', 'Organization.edit_and_add'],
    video: ['all'],
    notification: ['all'],
    videolifecycle: ['Video.monitor'],
    videolifecycle_summary: ['Video.monitor'],
    'account-manager': [
      'User.management.poseidon',
      'User.management.superuser',
      'User.management.user',
    ],
    'notification-settings': ['Notification.view', 'Notification.edit_and_add'],
    'action-logs': ['ActionLog.view'],
    dailyReport: ['Report.view', 'Report.download'],
    // functional
    videoDownload: ['Video.download'],
    organizationUpdate: ['Organization.edit_and_add'],
    notificationUpdate: ['Notification.edit_and_add'],
    permissionEdit: ['User.management.poseidon', 'User.management.superuser'],
    'sn-mappings': ['SnMapping.edit_and_add', 'SnMapping.view'],
    reportDownload: ['Report.download'],
  };
  return permissionMaps[item];
};

export { normTool, datetimeFormatConvert, parseObjectValues, getPermissionMap, getPercent };
