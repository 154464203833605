import axios from 'axios';
import * as humps from 'humps';
import { BACKEND_BASE_URL } from '../constants';
import Auth from '../components/Auth';

export const backendAPI = axios.create({
  baseURL: BACKEND_BASE_URL,
});

export interface IBackendResponse<T> {
  status: boolean;
  msg: string;
  msgs: string[];
  resultObj: T;
}

export const setDefaultHeader = (config: { [key: string]: any }) => {
  backendAPI.defaults.headers.common = {
    ...axios.defaults.headers.common,
    ...config,
  };
};

export const callBackendAPI: <T, BODY = any>(
  path: string,
  body: BODY,
  config: {
    defaultData: T;
    method?: 'post' | 'get' | 'delete' | 'put' | 'patch';
    baseURL?: string;
    headers?: T;
  },
) => Promise<T> = async (path, body, { defaultData, method = 'post', baseURL, headers }) => {
  let data: any = defaultData;

  try {
    const token = await Auth.getToken();
    setDefaultHeader({ Authorization: `Bearer ${token}` });
    const res = await backendAPI(path, {
      baseURL,
      method,
      data: body,
      headers,
    });
    data = humps.camelizeKeys(res.data);
  } catch (err) {
    // When server timeout, err.response will be undefined
    if (err && err.response) {
      // console.error('[Error]', err.response);
      if (err.response.status >= 500) {
        throw new Error('Service temporarily unavailable');
      }
      throw err;
    }

    throw err;
  }
  return data;
};

// Organization
export const getOrganization = async () =>
  callBackendAPI<Organization[]>('v1/organization', undefined, {
    method: 'get',
    defaultData: [],
  });

export const updateOrganization = async (
  id: string,
  name: string,
  organizationType: string,
  serialNumber: string,
  codeName: string,
  district: string,
) =>
  callBackendAPI<Organization>(
    `v1/organization/${id}`,
    {
      name,
      organization_type: organizationType,
      serial_number: serialNumber,
      code_name: codeName,
      district,
    },
    {
      method: 'patch',
      defaultData: {
        id: '',
        name: '',
        createdAt: '',
        qlauncher: '',
        organizationType: '',
        updatedAt: '',
        status: 0,
        serialNumber: '',
        codeName: '',
        devices: [],
        qlaunchers: [],
        calculated: {},
        enabled: true,
        district: '',
        noticeSuperEnable: false,
        noticeUserEnable: false,
      },
    },
  );

export const createOrganization = async (
  name: string,
  organizationType: string,
  serialNumber: string,
  codeName: string,
  district: string,
) =>
  callBackendAPI<Organization>(
    'v1/organization',
    {
      name,
      organization_type: organizationType,
      serial_number: serialNumber,
      code_name: codeName,
      district,
    },
    {
      method: 'post',
      defaultData: {
        id: '',
        name: '',
        createdAt: '',
        qlauncher: '',
        organizationType: '',
        updatedAt: '',
        status: 0,
        serialNumber: '',
        codeName: '',
        devices: [],
        qlaunchers: [],
        calculated: {},
        enabled: true,
        district: '',
        noticeSuperEnable: false,
        noticeUserEnable: false,
      },
    },
  );

export const getOrganizationDetail = async (id: string) =>
  callBackendAPI<Organization>(`v1/organization/${id}`, undefined, {
    method: 'get',
    defaultData: {
      id: '',
      name: '',
      createdAt: '',
      qlauncher: '',
      organizationType: '',
      updatedAt: '',
      status: 0,
      serialNumber: '',
      codeName: '',
      devices: [],
      qlaunchers: [],
      calculated: {},
      enabled: true,
      district: '',
      noticeSuperEnable: false,
      noticeUserEnable: false,
    },
  });

export const setOrganizationState = async (
  id: string,
  noticeSuperEnable: boolean,
  noticeUserEnable: boolean,
  enabled: boolean,
) =>
  callBackendAPI<Organization>(
    `/v1/organization/${id}`,
    {
      notice_super_enable: noticeSuperEnable,
      notice_user_enable: noticeUserEnable,
      enabled: enabled,
    },
    {
      method: 'patch',
      defaultData: {
        id: '',
        name: '',
        createdAt: '',
        qlauncher: '',
        organizationType: '',
        updatedAt: '',
        status: 0,
        serialNumber: '',
        codeName: '',
        devices: [],
        qlaunchers: [],
        calculated: {},
        enabled: true,
        district: '',
        noticeSuperEnable: false,
        noticeUserEnable: false,
      },
    },
  );

export const getAvailableStagingServers = async () =>
  callBackendAPI<SnMapping[]>('/v1/organization/staging_servers', undefined, {
    method: 'get',
    defaultData: [],
  });

export const getAvailableStagingServersOfOrg = async (org_id: string) =>
  callBackendAPI<SnMapping[]>(`/v1/organization/staging_servers?org=${org_id}`, undefined, {
    method: 'get',
    defaultData: [],
  });

// Device
export const createDevice = async (
  model: string,
  deviceType: string,
  cameraNumber: number,
  nvrId: string,
  organizationId: string,
  cameraList: string,
  sn: string,
) =>
  callBackendAPI<Device>(
    '/v1/devices',
    {
      model,
      device_type: deviceType,
      camera_number: cameraNumber,
      nvr_id: nvrId,
      organization: organizationId,
      camera_list: cameraList,
      sn,
    },
    {
      method: 'post',
      defaultData: {
        id: '',
        model: '',
        deviceType: '',
        cameraNumber: 0,
        cameraList: '',
        nvrId: '',
        createdAt: '',
        updatedAt: '',
        organization: '',
        sn: '',
        noticeSuperEnable: false,
        noticeUserEnable: false,
      },
    },
  );

export const getDeviceByOrganization = async (organizationId: string) =>
  callBackendAPI<Device[]>(`/v1/devices?organization=${organizationId}`, undefined, {
    method: 'get',
    defaultData: [],
  });

export const deleteDevice = async (id: string) =>
  callBackendAPI<Object>(`/v1/devices/${id}`, undefined, {
    method: 'delete',
    defaultData: {},
  });

export const updateDevice = async (
  id: string,
  model: string,
  deviceType: string,
  cameraNumber: number,
  nvrId: string,
  cameraList: string,
  sn: string,
) =>
  callBackendAPI<Device>(
    `/v1/devices/${id}`,
    {
      model,
      device_type: deviceType,
      camera_number: cameraNumber,
      nvr_id: nvrId,
      camera_list: cameraList,
      sn,
    },
    {
      method: 'patch',
      defaultData: {
        id: '',
        model: '',
        deviceType: '',
        cameraNumber: 0,
        cameraList: '',
        nvrId: '',
        createdAt: '',
        updatedAt: '',
        organization: '',
        sn: '',
        noticeSuperEnable: false,
        noticeUserEnable: false,
      },
    },
  );

// Qlauncher
export const getQlauncherByOrganization = async (organizationId: string) =>
  callBackendAPI<Qlauncher[]>(`/v1/qlauncher?organization=${organizationId}`, undefined, {
    method: 'get',
    defaultData: [],
  });

export const createQlauncher = async (sn: string, organizationId: string, port: number) =>
  callBackendAPI<Qlauncher>(
    '/v1/qlauncher',
    {
      organization: organizationId,
      sn,
      port,
    },
    {
      method: 'post',
      defaultData: {
        id: '',
        sn: '',
        createdAt: '',
        updatedAt: '',
        organization: '',
        noticeSuperEnable: false,
        noticeUserEnable: false,
        port: 2230,
      },
    },
  );

export const updateQlauncher = async (id: string, port: number) =>
  callBackendAPI<Qlauncher>(
    `/v1/qlauncher/${id}`,
    {
      port,
    },
    {
      method: 'patch',
      defaultData: {
        id: '',
        sn: '',
        createdAt: '',
        updatedAt: '',
        organization: '',
        noticeSuperEnable: false,
        noticeUserEnable: false,
        port: 2230,
      },
    },
  );

export const deleteQlauncher = async (id: string) =>
  callBackendAPI<Object>(`/v1/qlauncher/${id}`, undefined, {
    method: 'delete',
    defaultData: {},
  });

export const setQlauncherNotificationState = async (
  id: string,
  noticeSuperEnable: boolean,
  noticeUserEnable: boolean,
) =>
  callBackendAPI<Qlauncher>(
    `/v1/qlauncher/${id}`,
    {
      notice_super_enable: noticeSuperEnable,
      notice_user_enable: noticeUserEnable,
    },
    {
      method: 'patch',
      defaultData: {
        id: '',
        sn: '',
        createdAt: '',
        updatedAt: '',
        organization: '',
        noticeSuperEnable: false,
        noticeUserEnable: false,
        port: 2230,
      },
    },
  );

export const setDeviceNotificationState = async (
  id: string,
  noticeSuperEnable: boolean,
  noticeUserEnable: boolean,
) =>
  callBackendAPI<Device>(
    `/v1/devices/${id}`,
    {
      notice_super_enable: noticeSuperEnable,
      notice_user_enable: noticeUserEnable,
    },
    {
      method: 'patch',
      defaultData: {
        id: '',
        model: '',
        deviceType: '',
        cameraNumber: 0,
        cameraList: '',
        nvrId: '',
        createdAt: '',
        updatedAt: '',
        organization: '',
        sn: '',
        noticeSuperEnable: false,
        noticeUserEnable: false,
      },
    },
  );

export const setOrgNotificationState = async (
  id: string,
  noticeSuperEnable: boolean,
  noticeUserEnable: boolean,
) =>
  callBackendAPI<Organization>(
    `/v1/organization/${id}`,
    {
      notice_super_enable: noticeSuperEnable,
      notice_user_enable: noticeUserEnable,
    },
    {
      method: 'patch',
      defaultData: {
        id: '',
        name: '',
        createdAt: '',
        qlauncher: '',
        organizationType: '',
        updatedAt: '',
        status: 0,
        serialNumber: '',
        codeName: '',
        devices: [],
        qlaunchers: [],
        calculated: {},
        enabled: true,
        district: '',
        noticeSuperEnable: false,
        noticeUserEnable: false,
      },
    },
  );

// metadata
export const getVideoMetadata = async (
  orgId: string,
  startTime: number,
  endTime: number,
  nvrId: string,
  limit: number,
  offset: number,
  channels: string,
) =>
  callBackendAPI<PaginationVideoMetadata>(
    `v1/metadata/metadata_list?org_id=${orgId}&start_time=${startTime}&end_time=${endTime}&nvr_id=${nvrId}&limit=${limit}&offset=${offset}&channels=${channels}`,
    undefined,
    {
      method: 'get',
      defaultData: {
        count: 0,
        next: '',
        previous: '',
        results: [],
      },
    },
  );

// metadata search
export const search = async (
  search: string,
  limit: number,
  offset: number,
  orgId: string,
  startTime: number,
  endTime: number,
  nvrId: string,
  channels: string,
) =>
  callBackendAPI<PaginationVideoMetadata>(
    `v1/metadata/metadata_search?search=${search}&limit=${limit}&offset=${offset}&org_id=${orgId}&start_time=${startTime}&end_time=${endTime}&nvr_id=${nvrId}&channels=${channels}`,
    undefined,
    {
      method: 'get',
      defaultData: {
        count: 0,
        next: '',
        previous: '',
        results: [],
      },
    },
  );

// contact
export const createContact = async (name: string, phone: string, organizationId: string) =>
  callBackendAPI<Contact>(
    '/v1/contact',
    {
      name,
      phone,
      organization: organizationId,
    },
    {
      method: 'post',
      defaultData: {
        id: '',
        name: '',
        phone: '',
        createdAt: '',
        updatedAt: '',
        organization: '',
      },
    },
  );

export const updateContact = async (id: string, name: string, phone: string) =>
  callBackendAPI<Contact>(
    `/v1/contact/${id}`,
    {
      name,
      phone,
    },
    {
      method: 'patch',
      defaultData: {
        id: '',
        name: '',
        phone: '',
        createdAt: '',
        updatedAt: '',
        organization: '',
      },
    },
  );

export const getContact = async (orgId: string) =>
  callBackendAPI<Contact[]>(`/v1/contact?organization=${orgId}`, undefined, {
    method: 'get',
    defaultData: [],
  });

export const deleteContact = async (id: string) =>
  callBackendAPI<Object>(`/v1/contact/${id}`, undefined, {
    method: 'delete',
    defaultData: {},
  });

// auth
export const login = async (username: string, password: string) =>
  callBackendAPI<Status>(
    '/v1/auth/login',
    {
      username,
      password,
    },
    {
      method: 'post',
      defaultData: {
        success: false,
      },
    },
  );

// logout
export const logout = async () =>
  callBackendAPI<Object>('/v1/users/logout', undefined, {
    method: 'post',
    defaultData: {},
  });

export const loginOTP = async (username: string, password: string, otp: string) =>
  callBackendAPI<Token>(
    '/v1/auth/login',
    {
      username,
      password,
      otp,
    },
    {
      method: 'post',
      defaultData: {
        token: '',
      },
    },
  );

export const reSendOTP = async (username: string, password: string) =>
  callBackendAPI<Status>(
    '/v1/auth/resend_otp',
    {
      username,
      password,
    },
    {
      method: 'post',
      defaultData: {
        success: false,
      },
    },
  );

export const verify = async (token: string) =>
  callBackendAPI<Token>(
    '/v1/auth/verify',
    {
      token,
    },
    {
      method: 'post',
      defaultData: {
        token: '',
      },
    },
  );

// error_message
export const errorMessage = async (orgId: string, year: string, month: string) =>
  callBackendAPI<Notification[]>(
    `/v1/error_message?org_id=${orgId}&year=${year}&month=${month}`,
    { undefined },
    {
      method: 'get',
      defaultData: [],
    },
  );

// life cycle
export const videoLifeCycleList = async (
  orgId: string,
  status: number,
  limit: number,
  offset: number,
  error: number,
  channels: string,
  startTime: number,
  endTime: number,
  fileSize: number,
) =>
  callBackendAPI<PaginationVideoMetadata>(
    `v1/metadata/life_cycle?org_id=${orgId}&status=${status}&limit=${limit}&offset=${offset}&error=${error}&channels=${channels},&start_time=${startTime}&end_time=${endTime}&size_gt=${fileSize}`,
    undefined,
    {
      method: 'get',
      defaultData: {
        count: 0,
        next: '',
        previous: '',
        results: [],
      },
    },
  );

// life cycle search
export const searchLifeCycleList = async (
  search: string,
  limit: number,
  offset: number,
  orgId: string,
  status: number,
  error: number,
  channels: string,
  startTime: number,
  endTime: number,
  fileSize: number,
) =>
  callBackendAPI<PaginationVideoMetadata>(
    `v1/metadata/life_cycle_search?search=${search}&org_id=${orgId}&status=${status}&error=${error}&limit=${limit}&offset=${offset}&channels=${channels}&start_time=${startTime}&end_time=${endTime}&size_gt=${fileSize}`,
    undefined,
    {
      method: 'get',
      defaultData: {
        count: 0,
        next: '',
        previous: '',
        results: [],
      },
    },
  );

// dashboard report
export const getDashboardReport = async () =>
  callBackendAPI<DashboardReport>('/v1/report/dashboard', undefined, {
    method: 'get',
    defaultData: {
      organizationCount: 0,
      oneDayAgo: '',
      oneWeekAgo: '',
      oneMonthAgo: '',
      ipfsDayCount: 0,
      ipfsDaySize: '',
      ipfsWeekCount: 0,
      ipfsWeekSize: '',
      ipfsWeekSizeFour: '',
      ipfsMonthCount: 0,
      ipfsMonthSize: '',
      stagingDayCount: 0,
      stagingDaySize: '',
      stagingWeekCount: 0,
      stagingWeekSize: '',
    },
  });

// daily report
export const dailyReport = async (
  orgId: string,
  year: number,
  month: number,
  error: number,
  offset: number,
  limit: number,
) =>
  callBackendAPI<PaginationDailyReport>(
    `/v1/report/details?org_id=${orgId}&year=${year}&month=${month}&error=${error}&limit=${limit}&offset=${offset}`,
    undefined,
    {
      method: 'get',
      defaultData: {
        count: 0,
        next: '',
        previous: '',
        results: [],
      },
    },
  );

// refresh token
export const refreshToken = async (token: string) =>
  callBackendAPI<Token>(
    '/v1/auth/refresh-token',
    {
      token,
    },
    {
      method: 'post',
      defaultData: {
        token: '',
      },
    },
  );

export const getUser = async (id: string) =>
  callBackendAPI<User>(`/v1/users/${id}`, undefined, {
    method: 'get',
    defaultData: {
      id: '',
      username: '',
      group: '',
      phone: '',
      email: '',
      avatar: '',
      lastLogin: '',
      permission: [],
      createdAt: '',
      updatedAt: '',
      groupName: '',
      enabled: true,
      nickname: '',
      agency: '',
    },
  });

export const addUser = async (
  username: string,
  password: string,
  group: string,
  phone: string,
  email: string,
  permission: Object,
  nickname: string,
  agency: string,
) =>
  callBackendAPI<User>(
    '/v1/users',
    {
      username,
      group,
      phone,
      email,
      avatar: '',
      password,
      permission,
      nickname,
      agency,
    },
    {
      method: 'post',
      defaultData: {
        id: '',
        username: '',
        group: '',
        phone: '',
        email: '',
        avatar: '',
        lastLogin: '',
        permission: [],
        createdAt: '',
        updatedAt: '',
        groupName: '',
        enabled: true,
        nickname: '',
        agency: '',
      },
    },
  );

export const getUsers = async (user_type: string) =>
  callBackendAPI<User[]>(`/v1/users?user_type=${user_type}`, undefined, {
    method: 'get',
    defaultData: [],
  });

export const updateAdminUser = async (
  id: string,
  password: string,
  group: string,
  phone: string,
  email: string,
  nickname: string,
  agency: string,
) =>
  callBackendAPI<User>(
    `/v1/users/${id}`,
    {
      password,
      group,
      phone,
      email,
      nickname,
      agency,
    },
    {
      method: 'patch',
      defaultData: {
        id: '',
        username: '',
        group: '',
        phone: '',
        email: '',
        avatar: '',
        lastLogin: '',
        permission: [],
        createdAt: '',
        updatedAt: '',
        groupName: '',
        enabled: true,
        nickname: '',
        agency: '',
      },
    },
  );

export const updateUser = async (
  id: string,
  username: string,
  password: string,
  permission: [],
  group: string,
  phone: string,
  email: string,
  nickname: string,
  agency: string,
) =>
  callBackendAPI<User>(
    `/v1/users/${id}`,
    {
      username,
      password,
      permission,
      group,
      phone,
      email,
      nickname,
      agency,
    },
    {
      method: 'patch',
      defaultData: {
        id: '',
        username: '',
        group: '',
        phone: '',
        email: '',
        avatar: '',
        lastLogin: '',
        permission: [],
        createdAt: '',
        updatedAt: '',
        groupName: '',
        enabled: true,
        nickname: '',
        agency: '',
      },
    },
  );

export const deleteUser = async (id: string) =>
  callBackendAPI<Object>(`/v1/users/${id}`, undefined, {
    method: 'delete',
    defaultData: {},
  });

export const setUserState = async (id: string, enable: boolean) =>
  callBackendAPI<Object>(`/v1/users/${id}/set_state?enable=${enable}`, undefined, {
    method: 'patch',
    defaultData: {},
  });

export const getGroups = async () =>
  callBackendAPI<Group[]>('/v1/groups', undefined, {
    method: 'get',
    defaultData: [],
  });

export const getPermissions = async () =>
  callBackendAPI<Permission[]>('/v1/permissions', undefined, {
    method: 'get',
    defaultData: [],
  });

// notification settings
export const getNotificationOrgs = async () =>
  callBackendAPI<NotificationOrg[]>('/v1/notifications', undefined, {
    method: 'get',
    defaultData: [],
  });

export const getNotificationOrg = async (organizationOrgId: string) =>
  callBackendAPI<NotificationOrg>(`/v1/notifications/${organizationOrgId}`, undefined, {
    method: 'get',
    defaultData: {
      id: '',
      name: '',
      notificationNum: 0,
      deliveryMethod: [],
      organizations: [],
      types: [],
      enable: true,
      createdAt: '',
      updatedAt: '',
    },
  });

export const UpdateNotificationOrg = async (
  id: string,
  name: string,
  deliveryMethod: [],
  organizations: [],
  types: [],
  enable: boolean,
) =>
  callBackendAPI<NotificationOrg>(
    `/v1/notifications/${id}`,
    {
      name,
      deliveryMethod,
      organizations,
      types,
      enable,
    },
    {
      method: 'patch',
      defaultData: {
        id: '',
        name: '',
        notificationNum: 0,
        deliveryMethod: [],
        organizations: [],
        types: [],
        enable: true,
        createdAt: '',
        updatedAt: '',
      },
    },
  );

export const createOrganizationOrg = async (name: string) =>
  callBackendAPI<NotificationType>(
    '/v1/notifications',
    { name },
    {
      method: 'post',
      defaultData: {
        id: '',
        name: '',
        enable: true,
        createdAt: '',
        updatedAt: '',
      },
    },
  );

export const getNotificationTypes = async () =>
  callBackendAPI<NotificationType[]>('/v1/notification-types', undefined, {
    method: 'get',
    defaultData: [],
  });

export const getNotificationStaff = async (notificationId: string) =>
  callBackendAPI<NotificationStaff[]>(
    `/v1/notification-staff?notification=${notificationId}`,
    undefined,
    {
      method: 'get',
      defaultData: [],
    },
  );

export const updateNotificationStaff = async (
  id: string,
  name: string,
  enable: boolean,
  email: string,
  phone: string,
) =>
  callBackendAPI<NotificationStaff>(
    `/v1/notification-staff/${id}`,
    {
      name,
      enable,
      phone,
      email,
    },
    {
      method: 'patch',
      defaultData: {
        id: '',
        name: '',
        enable: true,
        phone: '',
        email: '',
        createdAt: '',
        updatedAt: '',
      },
    },
  );

export const createNotificationStaff = async (
  name: string,
  enable: boolean,
  email: string,
  phone: string,
  notificationOrgId: string,
) =>
  callBackendAPI<NotificationStaff>(
    '/v1/notification-staff',
    {
      name,
      enable,
      phone,
      email,
      notifaction_org: notificationOrgId,
    },
    {
      method: 'post',
      defaultData: {
        id: '',
        name: '',
        enable: true,
        phone: '',
        email: '',
        createdAt: '',
        updatedAt: '',
      },
    },
  );

// get district
export const getDistrict = async () =>
  callBackendAPI<DistrictType[]>('/v1/districts', undefined, {
    method: 'get',
    defaultData: [],
  });

// get me
export const getMe = async () =>
  callBackendAPI<User>('/v1/users/me', undefined, {
    method: 'get',
    defaultData: {
      id: '',
      lastLogin: '',
      username: '',
      email: '',
      avatar: '',
      phone: '',
      createdAt: '',
      updatedAt: '',
      group: '',
      permission: [],
      groupName: '',
      enabled: false,
      nickname: '',
      agency: '',
    },
  });

export const getActionLogs = async (limit: number, offset: number) =>
  callBackendAPI<PaginationActionLog>(`v1/action_logs?limit=${limit}&offset=${offset}`, undefined, {
    method: 'get',
    defaultData: {
      count: 0,
      next: '',
      previous: '',
      results: [],
    },
  });

export const getActionLogsWithFilter = async (
  year: number,
  month: number,
  action: string,
  status: number,
  limit: number,
  offset: number,
) =>
  callBackendAPI<PaginationActionLog>(
    `v1/action_logs?year=${year}&month=${month}&action=${action}&status=${status}&limit=${limit}&offset=${offset}`,
    undefined,
    {
      method: 'get',
      defaultData: {
        count: 0,
        next: '',
        previous: '',
        results: [],
      },
    },
  );

export const getSnMappings = async () =>
  callBackendAPI<SnMapping[]>('/v1/sn_mappings', undefined, {
    method: 'get',
    defaultData: [],
  });

export const getSnMapping = async (id: string) =>
  callBackendAPI<SnMapping>(`/v1/sn_mappings/${id}`, undefined, {
    method: 'get',
    defaultData: {
      id: '',
      createdAt: '',
      updatedAt: '',
      server: '',
      qlauncher: '',
    },
  });

export const addSnMapping = async (server: string, qlauncher: string) =>
  callBackendAPI<SnMapping>(
    '/v1/sn_mappings',
    {
      server,
      qlauncher,
    },
    {
      method: 'post',
      defaultData: {
        id: '',
        createdAt: '',
        updatedAt: '',
        server: '',
        qlauncher: '',
      },
    },
  );

export const updateSnMapping = async (id: string, server: string, qlauncher: string) =>
  callBackendAPI<SnMapping>(
    `/v1/sn_mappings/${id}`,
    {
      server,
      qlauncher,
    },
    {
      method: 'patch',
      defaultData: {
        id: '',
        createdAt: '',
        updatedAt: '',
        server: '',
        qlauncher: '',
      },
    },
  );

export const deleteSnMapping = async (id: string) =>
  callBackendAPI<Object>(`/v1/sn_mappings/${id}`, undefined, {
    method: 'delete',
    defaultData: {},
  });

// NVR Monitor
export const getDashboardMonitor = async () =>
  callBackendAPI<DashboardMonitor>('v1/report/nvr_dashboard', undefined, {
    method: 'get',
    defaultData: {
      lastUpdate: '',
      results: [],
    },
  });

// VideoLifeCycleSummary
export const getVideoLifeCycleSummary = async () =>
  callBackendAPI<VideoLifeCycleSummary[]>('v1/metadata/life_cycle_summary', undefined, {
    method: 'get',
    defaultData: [],
  });

// VideoLifeCycleSummaryDetail
export const getVideoLifeCycleSummaryDetail = async (date: string) =>
  callBackendAPI<VideoLifeCycleSummaryDetail[]>(
    `v1/metadata/life_cycle_summary_detail?date=${date}`,
    undefined,
    {
      method: 'get',
      defaultData: [],
    },
  );

// VideoLifeCycleSummaryDetailFile
export const getVideoLifeCycleSummaryDetailFile = async (
  nvrId: string,
  date: string,
  stage: string,
) =>
  callBackendAPI<VideoLifeCycleSummaryDetailFile[]>(
    `v1/metadata/life_cycle_summary_detail_file?nvr_id=${nvrId}&date=${date}&stage=${stage}`,
    undefined,
    {
      method: 'get',
      defaultData: [],
    },
  );

export const downloadVideoUrl = `${BACKEND_BASE_URL}/v1/video_file`;
export const downloadDailyReportUrl = `${BACKEND_BASE_URL}/v1/report/fileExport`;
export const downloadErrorMessageUrl = `${BACKEND_BASE_URL}/v1/error_message/fileExport`;
export const downloadUsersUrl = `${BACKEND_BASE_URL}/v1/users/fileExport`;
export const downloadActionLogUrl = `${BACKEND_BASE_URL}/v1/action_logs/fileExport`;
