import { login, logout, loginOTP, verify } from '../apis';
import { getPermissionMap } from '../utils/utils';

class Auth {
  constructor() {
    this.authenticated = false;
    this.permissions = [];
    this.me = null;
    this.interval = null;
  }

  async verify() {
    if (localStorage.getItem('key')) {
      try {
        const key = await verify(localStorage.getItem('key'));
        if (key) {
          this.authenticated = true;
          return true;
        }
      } catch (err) {
        return false;
      }
    }
    return false;
  }

  async getToken() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(async () => {
      await logout();
      localStorage.removeItem('key');
      this.authenticated = false;
      window.location.href = '/';
    }, 30 * 60 * 1000); // logout if idle for 30 mins

    if (localStorage.getItem('key')) {
      try {
        return localStorage.getItem('key');
      } catch (err) {
        return '';
      }
    }
    return '';
  }

  async login(username, password) {
    try {
      const key = await login(username, password);
      if (key.success) {
        return [true, ''];
      }
    } catch (err) {
      return [false, err];
    }
  }

  async loginOTP(username, password, OTP) {
    try {
      const key = await loginOTP(username, password, OTP);
      if (key.token) {
        localStorage.setItem('key', key.token);
        this.authenticated = true;
        return true;
      }
    } catch (err) {
      return false;
    }
  }

  async logout(cb) {
    await logout();
    localStorage.removeItem('key');
    this.authenticated = false;
    cb();
  }

  isAuthenticated() {
    if (localStorage.getItem('key')) {
      this.refreshToken();
    }
    return this.authenticated;
  }

  refreshToken() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(async () => {
      await logout();
      localStorage.removeItem('key');
      this.authenticated = false;
      window.location.href = '/';
    }, 30 * 60 * 1000); // logout if idle for 30 mins
  }

  hasPermission(item) {
    const needPermission = getPermissionMap(item);
    return needPermission.includes('all')
      ? true
      : this.permissions.some((p) => needPermission.includes(p));
  }

  setPermission(permissions) {
    this.permissions = permissions.map(({ clsName }) => clsName);
  }
}

export default new Auth();
