export const logoNegative = [
  '20 25',
  `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="20px" height="25px" viewBox="0 0 20 25" enable-background="new 0 0 20 25" xml:space="preserve">
<g id="Layer_4_1_">
	<path fill="#B5B6B6" d="M4.186,22.227c-0.312-0.355-0.709-1.283-0.991-1.661c-0.294-0.396-0.923-1.067-1.276-1.41
		c-0.116-0.113-0.575-0.441-0.575-0.441s0.877-0.053,1.519,0.11c0.436,0.112,1.432,1.018,1.66,1.586
		c0.014,0.036,0.547,0.545,1.112,1.706c0.358,0.737,0.315,1.449,0.315,1.449S4.562,22.653,4.186,22.227z"/>
	<path fill="#B5B6B6" d="M6.927,22.816c-0.158-0.223-0.139-1.276-0.119-1.626c0.021-0.425,0.322-1.252,0.525-1.625
		c0.201-0.369,1.053-1.289,1.053-1.289s-0.482,1.063-0.59,1.436c-0.206,0.991-0.453,2.207-0.258,2.541
		c0.179,0.289,0.53,0.221,0.53,0.221S7.938,23.13,7.39,23.13c-0.174,0-0.146-0.106-0.244-0.175
		C7.074,22.904,6.972,22.879,6.927,22.816z"/>
	<path fill="#B5B6B6" d="M13.868,22.216c-2.561,1.066-2.566,1.607-3.904,2.196c-1.076,0.473-2.941,0.646-2.941,0.646
		c0.7-0.822,0.717-0.761,1.066-1.205c0.459-0.586,1.305-1.152,1.957-1.511c0.523-0.286,1.885-0.844,2.22-1.095
		c0.523-0.393,1.128-0.451,1.545-0.451c1.975,0,2.726,0.63,2.726,0.63S15.936,21.354,13.868,22.216z"/>
</g>
<g id="Layer_2" enable-background="new">
	<path fill="#FCD007" d="M7.964,8.553l0.107-0.64l-6.44-1.292c0,0,0.092-1.437,1.157-2.859c1.119-1.493,2.976-1.589,2.976-1.589
		S6.688,0.492,8.09,0.07c1.405-0.42,3.345,0.218,3.345,0.218L9.667,8.903L7.964,8.553z"/>
</g>
<path fill="#28AC4A" enable-background="new" d="M3.582,13.365c0,0-0.846-0.359-1.78-1.535c-1.161-1.463-0.43-4.176-0.43-4.176
	L7.888,8.96l0.076-0.407l1.703,0.35l-1.679,8.251c0,0-1.456,0.115-3.071-0.932C3.567,15.347,3.582,13.365,3.582,13.365z"/>
<g id="Layer_3" enable-background="new">
	<path fill="#E51E25" d="M12.52,9.456l-1.681-0.371l1.755-8.432c0,0,1.844,0.01,3.115,0.998c1.378,1.069,1.337,2.861,1.337,2.861
		s1.06,0.282,1.877,1.947c0.913,1.855,0.225,3.739,0.225,3.739l-6.486-1.394L12.52,9.456z"/>
</g>
<path fill="#1569B3" enable-background="new" d="M13.49,17.199c-1.477,0.967-2.706,0.556-2.706,0.556L9.18,17.386l1.659-8.301
	l1.681,0.371l-0.11,0.487l6.501,1.241c0,0-0.102,1.75-1.234,3.042c-1.325,1.508-2.994,1.407-2.994,1.407S14.26,16.695,13.49,17.199z
	"/>
<path fill="#FFFFFF" d="M3.688,5.435c0,0,0.269-0.909,0.929-1.35c0.885-0.59,2.066-0.478,2.066-0.478s0.2-0.625,0.961-1.127
	c0.685-0.451,1.633-0.301,1.633-0.301L8.44,6.42L7.06,6.104L3.688,5.435z M16.877,6.127c-0.643-0.521-1.204-0.633-1.204-0.633
	S15.63,4.49,15.067,3.833c-0.451-0.523-1.245-0.685-1.245-0.685l-0.903,4.198l4.777,0.962C17.696,8.307,17.918,6.972,16.877,6.127z
	 M6.726,14.772l0.889-4.32L2.88,9.548c0,0-0.26,1.191,0.621,2.088c0.621,0.636,1.373,0.71,1.373,0.71s0.176,1.309,0.775,1.823
	C6.151,14.601,6.726,14.772,6.726,14.772z M13.858,14.246c0,0,1.449,0.167,2.382-0.666c0.554-0.495,0.637-1.189,0.637-1.189
	l-4.764-0.977l-0.857,4.277c0,0,1.089,0.128,1.741-0.373C13.461,14.96,13.858,14.246,13.858,14.246z"/>
</svg>
`,
];
